import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  " - 스테이지파이브, 한국알뜰통신사업자협회 회원사 가입",
  " - 알뜰통신사업에 대한 저변 확대 및 활성화를 위한 온라인 상생캠페인 선보일 예정",
]

const content = () => (
  <div>
    <style>
      {
        "\
          .pressDetailPage .pressDetailBody .contentArea .descImage img {\
            margin: auto;\
            width: 330px;\
          }\
        "
      }
    </style>
    <div className="descImage promotion">
      <div className="img">
        <img src="/images/press/7/HMuwnxCQ.jpg" alt="Figure1" />
      </div>
      <div className="desc">
        △ 23일 스테이지파이브(공동대표 이제욱, 서상원)가 서울 서대문구 알뜰폰스퀘어에서 한국알뜰통신사업자협회(회장 김형진)
        회원증을 받았다. 왼쪽부터 스테이지파이브 이세연 이사, 한국알뜰통신사업자협회 황성욱 상근부회장
      </div>
    </div>
    <p>
      <br />
      ㅣ 스테이지파이브(공동대표 이제욱, 서상원)가 한국알뜰통신사업자협회(이하 KMVNO) 회원사로 가입하여 알뜰통신사업 상생을 위한
      협력에 나섰다. 스테이지파이브는 23일 KMVNO 가입식을 갖고, 향후 알뜰통신사업 저변 확대와 해당 사업분야 활성화를 위한 온라인
      상생캠페인을 약속했다. 스테이지파이브는 올해 10월 27일 오픈한 서울 서대문구 소재 ‘알뜰폰스퀘어’를 통해 미러북, AI반려로봇 등
      알뜰통신 기반 IoT디바이스들을 선보인 바 있다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 한국알뜰통신사업자협회는 국내 무선재판매사업자(MVNO)로 구성된 사단법인 비영리단체로서, 회원사간의 공동협력 권익보호,
      양질의 통신서비스 제공을 위한 정책 개발 등 회원사 및 국내 통신산업의 발전에 기여함을 목적으로 설립되었다. 이날
      스테이지파이브의 가입으로 KMVNO회원사는 총 20개사가 됐다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 스테이지파이브 이제욱 대표는 “스테이지파이브는 지난 2017년 알뜰통신사업을 시작한 이래, 해당 생태계의 지속가능성을 높이기
      위한 노력을 지속해 왔다”며 “이번 협회 가입을 계기로 한국알뜰통신사업자협회 회원사들과 함께 알뜰통신사업의 방향성을 제시하고
      해당 사업이 가진 사회적 가치를 선도하는 도약점으로 삼을 것”이라고 강조했다.
      <br />
    </p>
    <br />
  </div>
)

const press7 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2020년 12월 23일 즉시 가능."
      pageInfo=""
      title="ㅣ 스테이지파이브-한국알뜰통신사업자협회, 알뜰폰 상생 위해 맞손"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press7
